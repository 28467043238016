import * as moment from 'moment';

export const DateNumeralFormat = 'l';         // 01/12/2020  'DD/MM/YYYY';
export const DateFormat = 'll';               // 01 Jan 2020 'DD MMM YYYY';
export const DateTimeFormat = 'll LTS';       // 'DD MMM YYYY HH:mm:ss';
export const ServerDateFormat = 'YYYY-MM-DD';
export const TimestampFormat = '(ddd) lll';
export const TimestampFormatShort = '(ddd) ll';
export const DateTimeFormatEn = 'MMM DD, YYYY h:mm:ss A';
export const DateTimeFormatFr = 'DD MMM YYYY HH:mm:ss';
export const InvalidDate = 'Invalid Date';

export const getDatePlaceholder = () => (moment().localeData() as any)._longDateFormat['L'];

export function FormatingDates<T>(list: T[], keyofData: keyof T, format = DateFormat, perUtc: boolean = true) {
  list.forEach((item: T) => {
    const date = item[keyofData];
    if (date) {
      item[keyofData] = FormatingDate(date.toString(), format, perUtc) as unknown as T[keyof T];
    }
  });
}

export function FormatingDate<T>(value: string | Date, format = DateFormat, perUtc: boolean = true) {
  return (perUtc ? moment.utc(value).local() : moment(value)).format(format);
}

export function FormatingDateToISO(value: string | Date, isEndDate?: boolean) {
  return `${moment(value).format(ServerDateFormat)}${isEndDate ? 'T23:59:59.999Z' : 'T00:00:00.000Z'}`;
}

export function FormatingDateTime(value: string | Date, perUtc: boolean = true) {
  return (perUtc ? moment.utc(value).local() : moment(value)).format(DateTimeFormat);
}

export function FormatingTimestamp(value?: string | Date) {
  const date = value ? moment.utc(value).local() : moment();
  return date.format(TimestampFormat);
}
