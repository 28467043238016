import { HttpClient, HttpParams } from '@angular/common/http';
import { IPageResponse } from 'app/shared/models';
import { environment } from '@env/environment';

export class OnlyHttpGetClass<T, QP extends { [key: string]: any } = { [key: string]: any }> {
  private readonly baseUrl;
  public totalElements: number = 2000;
  private queryParams: QP = {} as QP;
  constructor(
    private readonly http: HttpClient,
    private readonly endpoint: string,
    public readonly isTill?: boolean) {
    this.baseUrl = isTill ? environment.baseUrlTill : environment.baseUrl;
  }

  public waitForList(url?: string): Promise<T[]> {
    let httpParams: HttpParams;
    if (this.queryParams) httpParams = this._mapParams(this.queryParams);
    return this.http.get<T[]>(this.link(url), {params: httpParams})
      .toPromise()
      .then((response: T[] | IPageResponse<T>) => {
        if (!response) return [];
        const res = response as IPageResponse<T>;
        this.totalElements = res.totalElements;
        return (res.content ? res.content : response) as T[];
      });
  }

  public getItem(url?: string): Promise<T> {
    let httpParams: HttpParams;
    if (this.queryParams) httpParams = this._mapParams(this.queryParams);
    return this.http.get<T>(this.link(url), {params: httpParams})
      .toPromise();
  }

  public deletePropQueryParams(props: Array<keyof QP>) {
    props.forEach((prop) => delete this.queryParams[prop]);
  }

  public resetQueryParams() {
    this.queryParams = {} as QP;
  }

  public setPropQueryParams(props: QP) {
    Object.keys(props).forEach((prop: keyof QP) => this.queryParams[prop] = props[prop]);
  }

  private _mapParams(params: { [key: string]: any }): HttpParams {
    let httpParams = new HttpParams();
    Object.keys(params).forEach(key => {
      const param = params[key];
      if (Array.isArray(param)) {
        param.forEach(p => httpParams = httpParams.append(key, p));
      } else {
        httpParams = httpParams.append(key, param);
      }
    });
    return httpParams;
  }

  private link(url: string) {
    return `${this.baseUrl}${this.endpoint ? '/' + this.endpoint : ''}${url ? '/' + url : ''}`;
  }
}
