import { HttpClient } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

type THttpMethodName = 'get' |  'post' | 'put' | 'patch' | 'delete';

export abstract class HandleDoubleRequestAbstractClass {
  private lockLinks: string[] = [];
  private _subject = new Subject<any>();

  constructor(public readonly http: HttpClient) {
    this._subject.complete();
  }

  public checkDoubleRequest<T>(link: string, httpMethodName: THttpMethodName, arg: any[] = []): Observable<T> {
    if (!this.lockLinks.some(_ => _ === link)) {
      this.lockLinks.push(link);
      return this.http[httpMethodName as string](link, ...arg)
      .pipe(
        tap(_ => {
          this.filter(link);
        }),
        catchError(err => {
          this.filter(link);
          return throwError(err);
        }));
    } else {
      return this._subject;
    }
  }

  private filter(link: string) {
    this.lockLinks = this.lockLinks.filter(_ => _ !== link);
  }
}
