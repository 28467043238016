import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard, NonAuthGuard } from './core';
import {NonAuthOrResetGuard} from './core/guards/non-auth-or-reset.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: '',
    canActivateChild: [AuthGuard],
    loadChildren: () => import('./merchant/merchant.module').then(m => m.MerchantModule),
  },
  {
    path: 'till',
    loadChildren: () => import('./till/till.module').then(m => m.TillModule),
    canActivateChild: [AuthGuard]
  },
  {
    path: 'reset',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
    canActivateChild: [NonAuthGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
    canActivateChild: [NonAuthOrResetGuard]
  },
  {
    path: '**',
    redirectTo: '/login',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
