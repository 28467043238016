
import { AbstractHttpClass } from './abstract-http.class';
import { HttpClient } from '@angular/common/http';
import {
  IListResponse, IQueryParams, ESort, IPageResponse,
  QPConst, IPageable, ISortData, ELocalStorage
} from 'app/shared/models';
import { map } from 'rxjs/operators';

export class CrudClass<DTO, T, QP extends IQueryParams = IQueryParams> extends AbstractHttpClass<T> {
  private _queryParams: QP = {} as QP;
  private response: IListResponse<T> | IPageResponse<T>;

  constructor(
    public readonly http: HttpClient,
    readonly endpoint: string,
    readonly isTill?: boolean,
    private readonly notSetState?: boolean
    ) {
    super( http, endpoint, isTill);
  }

  public get list(): T[] {
    if (!this.response) this.setList();
    return this.response.content;
  }

  public get waitForList(): Promise<T[]> {
    return this.setList();
  }

  public deletePropQueryParams(props: Array<keyof QP>) {
    props.forEach((prop) => delete this._queryParams[prop]);
  }

  public setPropQueryParams(props: Partial<QP>) {
    Object.assign(this._queryParams, props);
    this._queryParams.page = props?.page || 0;
  }

  public resetQueryParams() {
    this._queryParams = {} as QP;
  }

  public get pagination(): IPageable {
    const res = this.response as IPageResponse<T>;
    return {
      first: res.first,
      last: res.last,
      countPages: res.totalPages,
      currentPage: res.number + 1,
    };
  }

  public get totalElements(): number {
    const res = this.response as IPageResponse<T>;
    return res ? res.totalElements : QPConst.totalEl;
  }

  public get queryParams() {
    return {...this._queryParams};
  }

  public create(item: DTO | FormData, url?: string): Promise<T[]> {
    return this.post(item, url)
      .toPromise()
      .then(this.setList);
  }

  public update(item: DTO | FormData, url?: string): Promise<T[]> {
    return this.patch(item, url)
      .toPromise()
      .then(this.setList);
  }

  public remove(id: string): Promise<T[]> {
    if (this.response) {
      const page = this._queryParams.page && this._queryParams.page - Number(this.list.length === 1);
      this._queryParams.page = page > 0 ? page : 0;
    }
    return this.delete(id)
      .toPromise()
      .then(this.setList);
  }

  public sort<SD extends ISortData<T>>(sortData: SD) {
    let sortName: string = sortData.keyOfData as string;
    if (sortData.keyOfNesting) {
      sortName += `.${sortData.keyOfNesting}`;
    }
    this._queryParams.sort = sortData.isDESC ? `${sortName},${ESort.DESC}` : `${sortName},${ESort.ASC}`;
    return this.setList();
  }

  public sortMultiColumn(params: string[]) {
    this._queryParams.sort = params;
    return this.setList();
  }

  public changePage(nextPage?: boolean | number) {
    const res = this.response as IPageResponse<T>;
    if (typeof nextPage === 'number') {
      this._queryParams.page = nextPage;
    } else {
      if (nextPage) {
        this._queryParams.page = res.totalPages !== res.number ? res.number + 1 : res.number;
      } else {
        this._queryParams.page = res.number ? res.number - 1 : res.number;
      }
    }
    return this.setList();
  }

  public searchByString(queryOrDate: string) {
    this._queryParams.search = queryOrDate;
    if (!queryOrDate) delete this._queryParams.search;
    delete this._queryParams.page;
    return this.setList();
  }

  private setList = () => {
    if (!this.response) {
      this.response = { content: [] };
    }
    if (!this.isTill  && !this.notSetState) {
      this.setState();
    }
    return this.getAll('', this._queryParams)
      .pipe(map((response: T[] | IPageResponse<T>) => {
        const res = response as IPageResponse<T>;
        if (res) {
          if (res.content) {
            this.list.splice(0, this.list.length, ...res.content);
            Object.keys(response).forEach(key => {
              if (key !== 'content') {
                this.response[key] = response[key];
              }
            });
          } else {
            this.list.splice(0, this.list.length, ...response as Array<T>);
          }
        }
        return this.list;
      })).toPromise();
  }

  private setState() {
    localStorage.setItem(ELocalStorage.QUERY_PARAMS, JSON.stringify({
       ...this._queryParams
    }));
  }
}
