import { Router } from '@angular/router';
import { ISerializable, ELocalStorage, ISerializableState } from 'app/shared/models';
import { fromEvent, Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '..';

export class SerializableClass<T> implements ISerializable<T> {
  private destroy = new Subject();
  private localName: ELocalStorage;
  constructor(public authService: AuthService, public router: Router) { }

  public init(fn: () => T, localName?: ELocalStorage) {
    this.localName = localName || ELocalStorage.SERIALIZED_DATA;
    combineLatest(
      [this.authService.onLogout(),
      fromEvent(window, 'beforeunload')]
    ).pipe(takeUntil(this.destroy))
    .subscribe(() => {
      localStorage.setItem(this.localName, JSON.stringify({
        redirectUrl: this.router.url,
        data: fn()
      }));
    });
  }

  public restoreState(obj: T): T {
    const data = localStorage.getItem(this.localName);
    localStorage.removeItem(this.localName);
    if (data) {
      const storedState: ISerializableState<T> = JSON.parse(data);
      if (storedState.redirectUrl === this.router.url) {
        Object.assign(obj || {}, storedState.data);
      }
    }
    return obj;
  }

  public unsubscribe() {
    this.destroy.next();
  }
}
