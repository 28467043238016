export enum ELanguage {
  EN = 'en',
  FR = 'fr'
}

export interface ILocationDTO {
  city: string;
  country: string;
  postalCode: string;
  province: string;
}

export enum ELocationCriteria {
  country = 'COUNTRY',
  province = 'PROVINCE',
  city = 'CITY',
  postalCode = 'POSTAL_CODE'
}

export interface IQPLocation extends ILocationDTO {
  page: number;
  field: string;
}
