
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '../services';
import { ELocalStorage, ETokenUrl } from 'app/shared/models';
import { LangService } from '../services/lang.service';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, switchMap, filter, take } from 'rxjs/operators';
import * as moment from 'moment-timezone';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private refreshing: boolean;

  constructor(private authService: AuthService, private langServ: LangService) {
  }

  public intercept<T>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    request = this.addHeadres(request);
    if (request.url.includes('i18n')) return next.handle(request);
    try {
      if (this.authService.token && !request.url.includes(ETokenUrl.REFRESH)) {
        const time = this.authService.parseTokenData.exp * 1000 - Date.now() - 100;
        if (time <= 0) return this.handle401Error(request, next);
      }
    } catch (error) {
      console.warn(error);
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 || error.url.includes(ETokenUrl.REFRESH)) {
          if (error.url.includes(ETokenUrl.REFRESH) || !this.authService.token) {
            this.authService.logout();
            this.refreshing = false;
          } else {
            return this.handle401Error(request, next);
          }
        }
        return throwError(error);
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.refreshing) {
      this.refreshing = true;
      this.refreshTokenSubject.next(null);
      return this.authService.refresh()
        .pipe(
          switchMap(() => {
            this.refreshing = false;
            this.refreshTokenSubject.next(this.authService.token);
            return next.handle(this.addHeadres(request));
          })
        );
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(_ => next.handle(this.addHeadres(request)))
      );
    }
  }

  private addHeadres(request: HttpRequest<any>) {
    request = request.clone({
      headers: request.headers.set('Cache-Control', 'no-cache, no-store, max-age=0, must-revalidate')
    });

    const zone = moment.tz.guess();
    if (zone) {
      request = request.clone({
        headers: request.headers.set('X-Timezone', zone)
      });
    }

    if (this.langServ) {
      request = request.clone({
        headers: request.headers.set('accept-language', this.langServ.currentLang ||
          localStorage.getItem(ELocalStorage.LANG) || navigator.language)
      });
    }

    if (this.authService.token || request.url.includes(ETokenUrl.TOKEN_TTL)) {
      if (!request.url.includes(`auth/${ETokenUrl.TOKEN}`) && !request.url.includes(ETokenUrl.REFRESH)) {
        request = request.clone({
          setHeaders: {
            'Authorization': `Bearer ${this.authService.token}`
          }
        });
      }
    }
    return request;
  }
}

