import { EThemeClass } from '.';
import { BsDatepickerConfig } from 'ngx-bootstrap';
import { DateFormat } from './date.model';

export interface IBaseDatePickerConfig extends Partial<BsDatepickerConfig> {
  isAnimated: boolean;
  dateInputFormat: string;
  containerClass: string;
  adaptivePosition: boolean;
  showWeekNumbers: boolean;
}

export function GetBaseDatePickerConfig(themeClass: EThemeClass): IBaseDatePickerConfig {
  return {
    isAnimated: true,
    dateInputFormat: DateFormat,
    containerClass: themeClass + ' theme-green',
    adaptivePosition: true,
    showWeekNumbers: false
  };
}
