export interface IPromoDTO {
  active: boolean;
  description: string;
  discount: number;
  endDate: string | Date;
  id: number;
  name: string;
  products: IPromoProductDTO[];
  startDate: string | Date;
}

export interface IPromoProductDTO {
  discountPrice: number;
  insertType: EInsertType;
  productId: number;
  isCustomisedPrice?: boolean;
}

export interface IPromoResponse {
  conflicting: IPromoProductDTO[];
  promoId: number;
  saved: IPromoProductDTO[];
}

export enum EInsertType {
  BASE = 'BASE',
  NEW = 'NEW',
  EXISTING = 'EXISTING'
}

export enum EPromoSelectUi {
  PRICE_GROUP = 'price-group',
  CATEGORY = 'category',
  DEPARTMENT = 'department',
  INDV_PRODUCT = 'individual-product'
}
