import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IStoreDTO, ELocalStorage } from 'app/shared/models';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AbstractHttpClass } from '../classes/abstract-http.class';
import { ThemeService } from './theme.service';

@Injectable({
  providedIn: 'root'
})
export class StoreService extends AbstractHttpClass<IStoreDTO> {

  constructor(http: HttpClient, public themeService: ThemeService) {
    super(http, 'store-setup');
  }

  public get store(): IStoreDTO {
    try {
      const store = localStorage.getItem(ELocalStorage.STORE);
      return store ? JSON.parse(store) : {};
    } catch (error) {
      return {} as IStoreDTO;
    }
  }

  public set store(store: IStoreDTO) {
    localStorage.setItem(ELocalStorage.STORE, JSON.stringify(store));
    if (store) this.themeService.themeDTO = store.colorScheme;
  }

  public update(store: FormData): Promise<IStoreDTO> {
    return this.put(store).toPromise().then((newStore) => this.store = newStore);
  }

  public load(): Observable<IStoreDTO> {
    return this.get().pipe(tap(store => this.store = store));
  }
}
