import { ICurrencyDTO, IPaymentMethodDTO } from './price.model';

export interface IStoreDTO {
  colorScheme: EThemeDTO;
  controlledProducts: boolean;
  country: string;
  defaultCurrency: ICurrencyDTO;
  paymentMethods: IPaymentMethodDTO[];
  id: number;
  lotteryTickets: boolean;
  quickFlip: boolean;
  state: string;
  storeCurrencies: IStoreCurrencyDTO[];
  address: string;
  city: string;
  contactCountry: string;
  contactProvince: string;
  logo: string;
  email: string;
  fax: string;
  lastName: string;
  phoneNumber: string;
  postalCode: string;
  storeName: string;
  tokenTTL: number;
  integratedCardPayment: boolean;
  emailedReceiptOption: IEmailedReceiptOption;
  website: string;
  receiptComment?: string;
  taxNumber?: string;
  seven24AccountName?: string;
  seven24AccessId?: string;
}

export interface IEmailedReceiptOption {
  enBody: string;
  enSubject: string;
  frBody: string;
  frSubject: string;
}

export interface IStoreCurrencyDTO {
  currency: ICurrencyDTO;
  exchangeRate: number;
  id?: number;
}

export enum EThemeClass {
  RED_GREY = 'rd',
  BLACK_ORANGE = 'gry',
  AQUA = 'turqoise',
  BLUE_GREY = 'df'
}

export enum EThemeDTO {
  RED_GREY = 'RED_GREY',
  BLACK_ORANGE = 'BLACK_ORANGE',
  AQUA = 'AQUA',
  BLUE_GREY = 'BLUE_GREY'
}

export interface IThemeUi {
  value: EThemeDTO;
  text: string;
}


