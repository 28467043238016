import { IProduct } from '.';
import { IProductDTO } from './product.model';

export interface IPrice {
  createdAt: string;
  currency: ICurrency;
  id: number;
  itemCost: number;
  margin: number;
  product?: IProduct;
  retailPrice: number;
  targetMargin: number;
  updatedAt: string;
}

export interface IPriceDTO {
  currency: ICurrencyDTO;
  id: number;
  itemCost: number;
  margin: number;
  retailPrice: number;
  targetMargin: number;
  discountPrice?: any;
}

export interface IPriceGroup {
  code: string;
  description: string;
  id: number;
  products: IProductDTO[];
  numberOfItems: number;
  price: number;
}

export interface IPriceGroupDTO {
  code: string;
  description: string;
  id?: number;
  products: IProductDTO[];
  price?: number;
  numberOfItems?: number;
}

export interface ICurrency {
  createdAt: string;
  id: number;
  name: string;
  updatedAt: string;
}

export interface ICurrencyDTO {
  id: number;
  name: string;
}

export interface IPaymentMethodDTO {
  id?: number;
  name: string;
  openDrawer: boolean;
}

export enum EPaymentMethods {
  CASH = 'CASH',
  CHECK = 'CHECK',
  SPLIT = 'SPLIT',
  DEBIT_CREDIT_PAYMENT = 'DEBIT/CREDIT'
}

export enum EPriceGroupSelectUi {
  CATEGORY = 'category',
  INDV_PRODUCT = 'individual-product'
}

export enum ECardType {
  DEBIT = 'DEBIT',
  CREDIT = 'CREDIT'
}
