import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Spinner$ } from '../components/spinner/spinner.component';

export enum EErrorText {
  UNIQ_NAME = 'Server error try again later!'
}

export interface IBpHttpErrorResponse extends HttpErrorResponse {
  validationErrors?: { field: string; message: string }[];
}

export interface IHasToastrServ {
  toastr: ToastrService;
}

export function ErrorMessageHandler(context: IHasToastrServ): (er: IBpHttpErrorResponse) => void {
  return function (er: IBpHttpErrorResponse) {
    Spinner$.next(false);
    switch (true) {
      case er.status === 401:
      case er.status === 403 && !er.url.includes('auth'):
        break;
      case !!er.error && !!er.error.validationErrors:
        er.error.validationErrors.forEach(err => {
          let text = err.message + ' ';
          context.toastr.error( text.trim() || EErrorText.UNIQ_NAME);
        });
        break;
      case typeof er.error === 'object' && er.error !== null:
        context.toastr.error(er.error.message ? er.error.message.replace(/\n/g, '<br />') : EErrorText.UNIQ_NAME);
        break;
      case typeof er.error === 'string':
        context.toastr.error(er.error ? er.error.replace(/\n/g, '<br />') : EErrorText.UNIQ_NAME);
        break;
      case typeof er.message === 'string':
        context.toastr.error(er.message ? er.message.replace(/\n/g, '<br />') : EErrorText.UNIQ_NAME);
        break;
      default:
        context.toastr.error(EErrorText.UNIQ_NAME);
        break;
    }
  };
}
