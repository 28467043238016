export interface IDiscount {
  amount: number;
  reason: string;
  type: string;
}

export enum EDigitalType {
  PERCENT = 'PERCENT',
  ABSOLUTE = 'ABSOLUTE'
}
