import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IPageResponse } from 'app/shared/models';
import { environment } from '@env/environment';
import { HandleDoubleRequestAbstractClass } from './handle-double-request-abstract.class';

export abstract class AbstractHttpClass<T> extends HandleDoubleRequestAbstractClass {
  private readonly baseUrl;

  protected constructor(
    public readonly http: HttpClient,
    public readonly endpoint: string,
    public readonly isTill?: boolean) {
    super(http);
    this.baseUrl = isTill ? environment.baseUrlTill : environment.baseUrl;
  }
  public get(url: string = '', params?: { [key: string]: any }): Observable<T> {
    let httpParams; const link = this.link(url);
    if (params) httpParams = this._mapParams(params);
    return this.checkDoubleRequest(link, 'get', [{ params: httpParams }]);
  }

  public getAll(url: string = '', params?: { [key: string]: any }): Observable<T[] | IPageResponse<T>> {
    let httpParams; const link = this.link(url);
    if (params) httpParams = this._mapParams(params);

    return this.checkDoubleRequest(link, 'get', [{ params: httpParams }]);
  }

  public post(body?: Object, url: string = ''): Observable<T> {
    const link = this.link(url);
    return this.checkDoubleRequest(link, 'post', [body]);
  }

  public postBlob(body?: Object, url: string = ''): Observable<Blob> {
    const link = this.link(url);
    return this.checkDoubleRequest(link, 'post' , [body, { responseType: 'blob' }]);
  }

  public put(body: Object, url: string = ''): Observable<T> {
    const link = this.link(url);
    return this.checkDoubleRequest(link, 'put', [body]);
  }

  public patch(body: Object, url: string = ''): Observable<T> {
    const link = this.link(url);
    return this.checkDoubleRequest(link, 'patch', [ body ]);
  }

  public delete(url: string, params?: { [key: string]: any }): Observable<T> {
    let httpParams; const link = this.link(url);
    if (params) httpParams = this._mapParams(params);
    return this.checkDoubleRequest(link, 'delete', [{ params: httpParams }]);
  }

  private _mapParams(params: { [key: string]: any }): HttpParams {
    let httpParams = new HttpParams();
    Object.keys(params).forEach(key => {
      const param = params[key];
      if (Array.isArray(param)) {
        param.forEach(p => httpParams = httpParams.append(key, p));
      } else {
        httpParams = httpParams.append(key, param);
      }
    });
    return httpParams;
  }

  private link(url: string) {
    return `${this.baseUrl}${this.endpoint ? '/' + this.endpoint : ''}${url ? '/' + url : ''}`;
  }
}
