export enum EFormElement {
  CHECKBOX = 'CHECKBOX',
  RADIO = 'RADIO',
  TOGGLE = 'TOGGLE',
  TEXTAREA = 'TEXTAREA',
  SELECT = 'SELECT',
  BOOL_SELECT = 'BOOL_SELECT',
  DATEPICKER = 'DATEPICKER',
  TYPEAHEAD = 'TYPEAHEAD',
  AUTOCOMPLETE = 'AUTOCOMPLETE'
}

export enum EPattern {
  WEBSITE = '^[(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)a-zA-Z0-9]+([\-\.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,5}(:[0-9]{1,5})?(\/.*)?',
  EMAIL = '^([\\w\\+-]+(?:\\.[\\w\\+-]+)*)@((?:[\\w-]+\\.)*\\w[\\w-]{0,66})\\.([a-zA-Z]{2,17}(?:\\.[a-zA-Z]{2,17})?)$',
  PHONE = '^[0-9\\-\\+\\s\\(\\)]*$',
  DIGITAL = '[0-9]*',
  GREATER_THAN_ZERO = '^[1-9][0-9]*$',
  FLOAT_2D = '^[0-9]*\\.?([0-9]{1,2})?$',
  FLOAT_3D = '^[0-9]*\\.?([0-9]{1,3})?$',
  FLOAT_4D = '^[0-9]*\\.?([0-9]{1,4})?$',
  NEGATIVE_FLOAT = '^\-?[0-9]*\\.?([0-9]{1,2})?$',
  PERCENTS_2D = '^[0-9]*\\.?([0-9]{1,2})?[%]?$',
  WITHOUT_SYMBOL = '[a-zA-Z0-9]+',
  INVOICE = '\.+',
  LIMIT_DIGITAL_366 = '^(?:[1-9]\\d?|[12]\\d{2}|3[0-5]\\d|36[0-5])$',
  MAC = '^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$',
  ONE_TO_9999 = '^(?:[1-9][0-9]{3}|[1-9][0-9]{2}|[1-9][0-9]|[1-9])$',
}

export enum EInputType {
  EMAIL = 'email',
  TEXT = 'text',
  PASSWORD = 'password'
}

export enum EInputMode {
  TEXT = 'text',
  TEL = 'tel',
  URL = 'url',
  EMAIL = 'email',
  NUMERIC = 'numeric',
  DECIMAL = 'decimal',
  SEARCH = 'search'
}

export enum EKeyCode {
  ENTER = 13,
  ESC = 27,
  TAB = 9,
  ARROW_DOWN = 40,
  ARROW_LEFT = 37,
  ARROW_UP = 38,
  ARROW_RIGHT = 39,
  PLUS = 107,
  PLUS1 = 187,
  SPACE = 32,
  SHIFT = 16,
  CTRL = 17,
  ALT = 18
}

export interface ITypeaheadOption {
  /** allow custom values */
  custom?: boolean;
  /** allow multiple values */
  multi?: boolean;
  /** use complex suggestions and results */
  complex?: boolean;
  /** Only for complex suggestions. Object's indicator property name used
   * as a value for form component. Can be just in combination with multi,
   * ut automatically cancels custom.
  Default value is id. */
  idField?: string;
}

export interface ISelectOptionUi<T> {
  id: T;
  valueUi: string;
}

export const INT = 2147483647;

export enum EFormStatus {
  VALID = 'VALID',
  INVALID = 'INVALID',
}
