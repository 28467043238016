import { IDiscount } from './discount.model';
import { IProductDTO } from './product.model';
import { IEmployeeDTO } from './employee.model';
import { ECardType, ICurrencyDTO, IPaymentMethodDTO } from './price.model';
import { ICustomerDTO } from './customer.model';
import { ICategoryDTO } from './category.model';
import { ITaxDTO } from './tax.model';
import { ERefundType, ETransactionType } from './till-refund.model';

export interface ITransaction {
  id?: string;
  cardType?: ECardType;
  currencyId: number;
  createdAt?: string;
  customerId: number;
  discount: IDiscount;
  items: ITransactionItem[];
  paymentMethodId: number;
  cashTendered: number;
  subtotal: number;
  transactionType?: string;
  cnpPayment?: boolean;
  cnpTransactionId?: string;
}

export interface ITransactionItem {
  price: number;
  product: IProductDTO;
  quantity: number;
  taxExempt?: number[];
}

export interface ITransactionResponse {
  cardType: ECardType;
  createdBy: IEmployeeDTO;
  cashTendered: number;
  roundingError: number;
  refundType?: ERefundType;
  customer?: ICustomerDTO;
  id: number;
  items: ITransactionItemDetailsResponse[];
  paymentMethod: IPaymentMethodDTO;
  currency: ICurrencyDTO;
  discount: number;
  discountReason: number;
  discountType: any;
  subtotal: number;
  subtotalWithDiscount: number;
  nonCardPayment?: boolean;
  reason?: string;
  total: number;
  change: number;
  transactionType: string | ETransactionType;
  transactionDatetime: string;
  exchangeRate?: number;
  splitTransactions: {
    cardType: ECardType;
    paymentMethod: IPaymentMethodDTO;
    currency: ICurrencyDTO;
    total: number;
    cashTendered: number;
    exchangeRate: number;
  }[];
}

export interface ITransactionItemDetailsResponse {
  linkedItems: ITillLinkedItemDTO[];
  discountAmount: number;
  id: number;
  price: number;
  product: IProductDTO;
  quantity: number;
  subtotal: number;
  subtotalWithDiscount: number;
  taxes: {
    amount: number;
    tax: ITaxDTO;
  }[];
  total: number;
}

export interface IExtendsTransactionItemDetails extends ITransactionItemDetailsResponse {
  taxesList: string;
  taxAmount: number;
  hideCheckbox?: boolean;
  refundQuantity?: number;
  selected?: boolean;
}

export interface ITillLinkedItemDTO {
  barcode: string;
  category: ICategoryDTO;
  description: string;
  id: number;
  price: number;
  refundQuantity?: number;
  taxes: {
    amount: number;
    tax: ITaxDTO;
  }[];
}

export interface IEmailReceiptRequest {
  email: string;
  transactionId: number;
}

export interface ILottoRedemption {
  amount: number;
  id?: number;
  reason: string;
}

export enum ETransactionStatus {
  OPENED = 'OPENED',
  COMPLETE = 'COMPLETE',
  REJECTED = 'REJECTED'
}


export interface IPaidInOutTransactionDTO {
  total: number;
  reason: string;
  cardType?: ECardType;
  id?: number;
  paymentMethodId?: number;
}

export enum EPaidURL {
  IN = 'paid-in',
  OUT = 'paid-out'
}

export interface ITransactionHistory {
  amount: number;
  currency: ICurrencyDTO;
  name: string;
  status: ETransactionStatus;
}
