import { ICategoryDTO } from './category.model';
import { IPriceDTO, IPriceGroupDTO } from './price.model';
import { IVendorDTO } from './vendor.model';
import { IAttributeDTO } from './attribute.model';
import { ITax, ITaxDTO } from './tax.model';
import { IQuickflip, IQuickflipDTO } from './quickflip.model';
import { ITillPrice } from './till-product.model';

export interface IProduct {
  addAPromo: boolean;
  attributes?: IAttributeDTO[];
  barcode: string;
  caseCost: number;
  caseQuantity: number;
  caseUpc: string;
  category?: ICategoryDTO;
  controlledProduct: boolean;
  createdAt: string;
  description: string;
  id: number;
  linkedItems: ILinkedItemDTO[];
  price?: IPriceDTO;
  priceGroup?: IPriceGroupDTO;
  purchaseByCase: boolean;
  restriction: boolean;
  showInQuickFlip: boolean;
  quickFlip: IQuickflip;
  quickFlipName: string;
  size: number;
  image: string;
  taxes?: ITax[];
  unit: string;
  updatedAt: string;
  maxOnhand: number;
  minOnhand: number;
  regularCost: number;
  vendor?: IVendorDTO;
  onHand?: number;
  onOrder?: number;
  vin: string;
}

export interface IProductDTO {
  addAPromo: boolean;
  attributes?: IAttributeDTO[];
  barcode: string;
  caseCost: number;
  caseQuantity: number;
  caseUpc: string;
  category?: ICategoryDTO;
  controlledProduct: boolean;
  description: string;
  id?: number;
  linkedItems?: ILinkedItemDTO[];
  onHand?: number;
  onOrder?: number;
  price?: IPriceDTO;
  priceGroup?: IPriceGroupDTO;
  purchaseByCase: boolean;
  restriction: boolean;
  showInQuickFlip: boolean;
  quickFlip: IQuickflipDTO;
  quickFlipName: string;
  size: number;
  image: string;
  taxes?: ITax[];
  unit: string;
  maxOnhand: number;
  minOnhand: number;
  regularCost: number;
  vendor?: IVendorDTO;
  vin: string;
}

export interface ILinkedItemDTO {
  barcode: string;
  category: ICategoryDTO;
  description: string;
  id: number;
  price: number;
  products: IProductDTO[];
  taxes: ITaxDTO[];
  department?: string;
  typeaheadUi?: string;
}

export interface IProductCardStorage {
  barcode: string;
  linkedItems: {
    id: number,
    activeTaxes: ITaxDTO[]
  }[];
  id: number;
  quantity: number;
  activeTaxes: ITaxDTO[];
  price: ITillPrice;
}

export interface IImportProduct {
  errors: number;
  products: IProductDTO[];
}

export const MIME_CSV: string[] = [
  'text/csv',
  'text/plain',
  'application/csv',
  'text/comma-separated-values',
  'application/excel',
  'application/vnd.ms-excel',
  'application/vnd.msexcel',
  'text/anytext',
  'application/octet-stream',
  'application/txt'
];
