import { ICustomerDTO } from './customer.model';
import { ECardType, EPaymentMethods, ICurrencyDTO, IPaymentMethodDTO } from './price.model';
import { IQueryParams } from './request.model';
import { ETransactionStatus, ITransactionResponse } from './transaction.model';

export interface IRefundTransaction {
  customerId?: number;
  retRef?: string;
  items: {
    productId: number;
    quantity: number;
    price?: number;
  }[];
  transactionId: number;
  type: ERefundType;
  paymentMethodId: number;
  cardType?: ECardType;
}

export interface IRefundResponseTransaction {
  id: number;
  payments: {
    cardType: ECardType;
    cashTendered: number;
    currency: ICurrencyDTO;
    exchangeRate: number;
    paymentMethod: IPaymentMethodDTO
    total: number;
  }[];
}

export interface IInitialState {
  customer: ICustomerDTO;
}

export interface IExtendedRefundQueryParams extends IQueryParams {
  customerId: number;
  dateFrom: string;
  transactionId: string;
  dateTo: string;
  transactionType?: ETransactionType;
  status: ETransactionStatus;
}

export interface IExtendedTransaction extends ITransactionResponse {
  itemsCount: number;
  clerk: string;
  taxAmount?: number;
  paymentMethodUi: string;
}

export enum ETransactionType {
  SELL_TRANSACTION = 'SELL_TRANSACTION',
  REFUNDS = 'REFUNDS',
  PAID_OUT = 'PAID_OUT',
  PAID_IN = 'PAID_IN',
  LOTTO_REDEMPTION = 'LOTTO_REDEMPTION',
  OPEN_DRAWER = 'OPEN_DRAWER'
}

export enum ERefundType {
  STORE = 'STORE',
  WRITE_OFF = 'WRITE_OFF'
}


export interface IAvailableRefund {
  amount:  number;
  cardId:  string;
  cardType: ECardType;
  paymentMethods: EPaymentMethods;
}
