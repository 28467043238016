export interface ILogin {
  password: string;
  username: string;
  tenantId: string;
}

export interface ITokenResponse {
  refreshToken: string;
  token: string;
}

export enum ETokenUrl {
  REFRESH = 'refresh',
  TOKEN = 'token',
  RESET = 'reset',
  TOKEN_TTL = 'token-ttl',
  PASSWORD_UPDATE = 'update-password'
}

export enum ELocalStorage {
  REFRESH_TOKEN = 'refresh',
  TOKEN = 'token',
  STORE = 'store',
  LANG = 'lang',
  TILL_CURRENT_TRANSACTION = 'till_current_transaction',
  SERIALIZED_DATA = 'serialized_data',
  SERIALIZED_MODAL_DATA = 'serialized_modal_data',
  REMEMBER_ME = 'remember_me',
  QUERY_PARAMS = 'query_params',
  TERMINAL = 'terminal',
  PRINTER = 'printer'
}

export enum ERole {
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_MANAGER = 'ROLE_MANAGER',
  ROLE_CASHIER = 'ROLE_CASHIER'
}

export interface IRole {
  id: ERole;
  valueUi: string;
}

export const ROLES: IRole[] = [
  {
    id: ERole.ROLE_ADMIN,
    valueUi: 'admin'
  },
  {
    id: ERole.ROLE_MANAGER,
    valueUi: 'manager'
  }, {
    id: ERole.ROLE_CASHIER,
    valueUi: 'cashier'
  }
];

export enum TokenExpiration {
  EXP15m = 900,
  EXP0 = 0,
  EXP12h = 43200
}

export interface IParseTokenData {
  aud: string;
  exp: number;
  newUser: boolean;
  iss: string;
  rol: ERole[];
  sub: string;
  ten: string;
  claims: any[];
}

