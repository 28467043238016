export function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.toLowerCase().slice(1);
}

export const compareStr = (a: string, b: string) => a > b ? 1 : -1;

export const frsort = (a: string, b: string) =>  a.localeCompare(b);

export function parsingStrUppercaseSeparator(value: string, separator: string = '_') {
  return value && value.split(separator).map(_ => _ && _[0] + _.toLowerCase().slice(1)).join(' ');
}
