import { IEmployeeDTO } from './employee.model';
import { IInvoiceDTO } from './invoice.model';

export interface ICreateReceivingDTO {
  invoice: number;
  purchaseOrderId?: number;
}

export interface IReceivingDTO {
  comment: string;
  createdAt: string;
  createdBy: IEmployeeDTO;
  id: number;
  invoice: IInvoiceDTO;
  status: EStatus;
}

export interface ICreateReceivingItemDTO {
  itemCost: number;
  receivingId: number;
  receivingItemId?: number;
  productId: number;
  quantity: number;
  retailPrice: number;
  targetMargin: number;
}

export interface IReceivingItemDTO {
  canBeUnpacked: boolean;
  caseQuantity: number;
  categoryName: string;
  dateOfReceiving: string;
  departmentName: string;
  description: string;
  id: number;
  itemCost: number;
  productId: number;
  quantityOnHand: number;
  quantityOrdered: number;
  quantityReceived: number;
  receivingNumber: string;
  refunded: boolean;
  retailPrice: number;
  targetMargin: number;
  upc: string;
}

export enum EStatus {
  DRAFT = 'DRAFT',
  COMPLETED = 'COMPLETED'
}

export interface IReceivingItemUnpackRequest {
  quantity: number;
  receivingItemId: number;
}

export interface IUnreceivedSoldItemDTO {
  barcode: string;
  description: string;
  productId: number;
  quantity: number;
  vendorId: number;
}

export interface IUnreceivedSoldItemCriteria {
  categoryId: number;
  departmentId: number;
  employeeId: number;
  productId: number;
  search:	string;
  vendorId: number;
}

export interface IRecevingComplete {
  complete: true;
  items: IRecevingCompleteItem[];
  receiving: ICreateReceivingDTO;
}

export interface IRecevingCompleteItem {
  itemCost: number;
  productId: number;
  quantity: number;
  receivingId?: number;
  receivingItemId?: number;
  retailPrice: number;
  targetMargin: number;
}
