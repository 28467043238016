import { IDiscount } from './discount.model';
import { IProductCardStorage } from './product.model';
import { ITaxDTO } from './tax.model';
import { ITransactionHistory } from './transaction.model';

export interface IPaymentInfo {
  due: number;
  paid: number;
  owed: number;
}

export enum EModalScreens {
  BeforePayment,
  AfterPayment,
  SendEmail
}

export interface  IStorageTill {
  products: IProductCardStorage[] | null;
  discount: IDiscount | null;
  splitTransactions: ITransactionHistory[];
  splitTransactionId: number;
  activeTaxes: ITaxDTO[];
}
