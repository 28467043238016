import { ErrorHandler, Injectable, ViewChild } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  @ViewChild(RouterOutlet) private outlet: RouterOutlet;

  public handleError(error: any): void {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    const activatedOutlet = /Cannot activate an already activated outlet/;

    if (chunkFailedMessage.test(error.message)) {
      window.location.reload();
    } else {
      throw error;
    }
  }
}
