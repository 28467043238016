import { Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export const Spinner$ = new BehaviorSubject<boolean>(false);

@Component({
  selector: 'spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent {
  public readonly spinner$ = Spinner$;

  constructor() { }
}
