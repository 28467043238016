import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { SerializableClass } from '../classes';

@Injectable({
  providedIn: 'root'
})
export class SerializableService<T> extends SerializableClass<T> {
  constructor(public authService: AuthService, public router: Router) {
    super(authService, router);
  }
}
