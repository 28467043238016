import { IEmployeeDTO } from './employee.model';
import { ITransactionResponse } from './transaction.model';

export interface ICustomerDTO {
  address: string;
  billingContact: string;
  city: string;
  code: string;
  commPref: keyof ECommPrefUi;
  country: string;
  createdBy: IEmployeeDTO;
  email: string;
  fax: string;
  firstName: string;
  id: number;
  lastName: string;
  lastTransaction?: ITransactionResponse;
  mobile: string;
  phone: string;
  postalCode: string;
  province: string;
}

export enum ECommPrefUi {
  EMAIL = 'email',
  PHONE = 'phone',
  MOBILE = 'mobile'
}

export interface ICommPrefUi {
  id: keyof ECommPrefUi;
  valueUi: string;
}

