
import { Injectable } from '@angular/core';
import { EThemeClass, EThemeDTO } from 'app/shared/models';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private _themeClass: EThemeClass;
  private _themeDTO: EThemeDTO;

  constructor() {
    this.themeDTO = EThemeDTO.AQUA;
  }

  public get themeClass(): EThemeClass {
    return this._themeClass;
  }

  public get themeDTO(): EThemeDTO {
    return this._themeDTO;
  }

  public set themeDTO(theme: EThemeDTO) {
    this._themeDTO = theme;
    this._themeClass = EThemeClass[theme];
  }
}

