// ** dateFrom: iso 8601 */
export interface IQueryParams {
  sort?: string | string[];
  page?: number;
  size?: number;
  search?: string;
  pageable?: boolean;
}

export enum ESort {
  ASC = 'ASC',
  DESC = 'DESC'
}

export const QPConst = {
  size: 20,
  totalEl: 2000
};
