import { Directive, HostListener } from '@angular/core';
import { EKeyCode } from 'app/shared/models';

export enum EMissGlobalKeyEvent {
  class = 'missGlobalKeyEvent',
  selector = '.missGlobalKeyEvent'
}

@Directive({
  selector: '[bpAutoFocus]'
})
export class BpAutoFocusDirective {


  constructor() { }

  @HostListener('window:keyup', ['$event'])
  public keyEvent(event: KeyboardEvent) {
    // tslint:disable-next-line: deprecation
    if (event.keyCode === EKeyCode.ENTER) {
      const btn = <HTMLElement>document.querySelector(`.modal .btn.btn-prm:not(.is-disabled):not(${EMissGlobalKeyEvent.selector})`);
      if (btn) btn.click();
    // tslint:disable-next-line: deprecation
    } else if (event.keyCode === EKeyCode.TAB) {
      const modalSelector = 'modal-container';
      const targetEl = event.target as HTMLElement;
      if (
        (document.querySelector(modalSelector) &&
          targetEl.closest('app-root')) ||
        targetEl.closest(EMissGlobalKeyEvent.selector) ||
        targetEl.className.includes(EMissGlobalKeyEvent.selector)
      ) {
        document.getSelection().removeAllRanges();
        targetEl.blur();
      }
    }
  }
}
