import { Component, OnInit } from '@angular/core';
import { ThemeService } from './core/services/theme.service';
import { LangService } from 'app/core/services/lang.service';
import * as moment from 'moment';
import localeFr from '@angular/common/locales/fr';
import { registerLocaleData } from '@angular/common';
import { capitalize, ELanguage } from './shared/models';
import { StoreService, AuthService } from './core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(public themeService: ThemeService,
    public langServ: LangService, storeServ: StoreService, authServ: AuthService) {
    langServ.initLanguage();
    moment.locale(langServ.currentLang);
    if (authServ.token) {
      storeServ.load().subscribe();
    }
  }

  public ngOnInit(): void {
    registerLocaleData(localeFr, ELanguage.FR);
    document.head.querySelector('title').text = capitalize(location.hostname.split('.')[0]);
  }
}
