import { Injectable } from '@angular/core';
import { ELocalStorage, ELanguage } from 'app/shared/models';
import { TranslateService } from '@ngx-translate/core';
import { StoreService } from './store.service';

@Injectable({
  providedIn: 'root'
})
export class LangService {
  private _currentLang: string;

  constructor(
    private storeService: StoreService,
    public translateService: TranslateService) {
  }

  public get currentLang() {
    return this._currentLang;
  }

  public initLanguage() {
    const userLang = localStorage.getItem(ELocalStorage.LANG) || navigator.language;
    if (userLang.startsWith(ELanguage.FR)) {
      this.translateService.setDefaultLang(ELanguage.FR);
      this.translateService.use(ELanguage.FR);
    } else {
      this.translateService.setDefaultLang(ELanguage.EN);
      this.translateService.use(ELanguage.EN);
    }

    this._currentLang = this.translateService.currentLang;
  }

  public changeLanguage(language: ELanguage) {
    this.translateService.use(language);
    localStorage.setItem(ELocalStorage.LANG, language);
    this._currentLang = this.translateService.currentLang;
  }


  public i18(value: string): string {
    const country = this.storeService.store.country ? this.storeService.store.country : '';
    return this.i18WithCountry(value, country);
  }

  public i18WithCountry(value: string, country: string): string {
    const key = `${value}_${country.toUpperCase()}`;
    const result = this.translateService.instant(key);

    // if 'state_CA' not found try to find 'state'
    return key === result ? this.translateService.instant(value) : result;
  }

  public getSymbol(value: string): string {
    switch (value) {
      case ':':
        return this._currentLang === ELanguage.FR ? ' : ' : ': ';
      case '%':
        return this._currentLang === ELanguage.FR ? ' % ' : '%';
      default:
        return value;
    }
  }
}
