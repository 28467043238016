import {
  ITokenResponse, ILogin, ELocalStorage,
  ETokenUrl, IChangePasswordRequest,
  IFogotPasswordRequest, TokenExpiration, IParseTokenData
} from 'app/shared/models';
import { HttpClient } from '@angular/common/http';
import { StoreService } from './store.service';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { fromEvent, merge, Observable, Subject } from 'rxjs';
import { NavigationStart, Router } from '@angular/router';
import { HandleDoubleRequestAbstractClass } from '../classes/handle-double-request-abstract.class';
import { filter, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends HandleDoubleRequestAbstractClass {
  private logoutSubject = new Subject();
  private timerId: ReturnType<typeof setTimeout>;
  constructor(http: HttpClient,
    private router: Router, public storeService: StoreService) {
    super(http);
    this.setEventHandlerForCheckingToken();
  }

  public login(value: ILogin) {
    const link = `${environment.authUrl}/${ETokenUrl.TOKEN}`;
    return this.checkDoubleRequest(link, 'post', [value])
      .pipe(tap((res: ITokenResponse) => this.setData(res)));
  }

  public passwordReset(password: string) {
    const link = `${environment.authUrl}/${ETokenUrl.RESET}`;
    return this.checkDoubleRequest(link, 'post', [password])
      .pipe(tap((res: ITokenResponse) => this.setData(res)));
  }

  public passwordUpdate(password: string) {
    const link = `${environment.authUrl}/${ETokenUrl.PASSWORD_UPDATE}`;
    return this.checkDoubleRequest(link, 'post', [{'password': password, 'token': this.refreshToken}])
      .pipe(tap((res: ITokenResponse) => this.setData(res)));
  }

  public refresh() {
    const link = `${environment.authUrl}/${ETokenUrl.REFRESH}`;
    return this.checkDoubleRequest(link, 'post', [this.refreshToken])
      .pipe(
        tap((res: ITokenResponse) => {
          this.setData(res);
          this.storeService.load().subscribe();
        })
      );
  }

  public fogotPassword(body: IFogotPasswordRequest) {
    const link = `${environment.baseUrl}/employee/reset-password`;
    return this.checkDoubleRequest(link, 'post', [body]);
  }

  public resetPassword(resetData: IChangePasswordRequest) {
    const link = `${environment.baseUrl}/employee/reset-password`;
    return this.checkDoubleRequest(link, 'put', [resetData]);
  }

  private get refreshToken(): string {
    return localStorage.getItem(ELocalStorage.REFRESH_TOKEN);
  }

  private set refreshToken(value: string) {
    localStorage.setItem(ELocalStorage.REFRESH_TOKEN, value);
  }

  public get token(): string {
    return localStorage.getItem(ELocalStorage.TOKEN);
  }

  public set token(value: string) {
    localStorage.setItem(ELocalStorage.TOKEN, value);
  }

  public get parseTokenData(): IParseTokenData {
    return JSON.parse(atob(this.token.split('.')[1]));
  }

  public get isNewUserAndNotAnonymous(): boolean {
    return this.token && this.isNewUser;
  }
  public get isNewUser(): boolean {
    const parsedData = this.parseTokenData;
    return parsedData.newUser;
  }

  public logout() {
    this.localStorageClearData();
    this.logoutSubject.next();
    this.router.navigate(['/login']);
  }

  public onLogout(): Observable<any> {
    return this.logoutSubject.asObservable();
  }

  private setData(response: ITokenResponse) {
    try {
      this.refreshToken = response.refreshToken;
      this.token = response.token;
      this.tokenExpirationHandler();
    } catch (error) {
      console.warn('error:', error);
      console.warn('response:', response);
    }
  }

  public tokenExpiration(ttl: TokenExpiration) {
    return this.http.put(environment.authUrl.replace('/auth', '/merchant/token-ttl'),
      null, {
      params: {
        ttl: ttl.toString()
      }
    }).subscribe(_ => this.refresh());
  }

  private tokenExpirationHandler() {
    clearTimeout(this.timerId);
    try {
      if (!this.refreshToken) return;
      const time = JSON.parse(atob(this.refreshToken.split('.')[1])).exp * 1000 - Date.now() - 100;
      if (time <= 0) return this.logout();
      this.timerId = setTimeout(_ => this.logout(), time);
    } catch (error) {
      console.warn(error);
    }
  }

  private localStorageClearData() {
    localStorage.removeItem(ELocalStorage.TOKEN);
    localStorage.removeItem(ELocalStorage.REFRESH_TOKEN);
    localStorage.removeItem(ELocalStorage.STORE);
    localStorage.removeItem(ELocalStorage.LANG);
    localStorage.removeItem(ELocalStorage.PRINTER);
    localStorage.removeItem(ELocalStorage.TERMINAL);
  }

  private setEventHandlerForCheckingToken() {
    let stateKey, eventKey;
    const keys = {
      hidden: 'visibilitychange',
      webkitHidden: 'webkitvisibilitychange',
      mozHidden: 'mozvisibilitychange',
      msHidden: 'msvisibilitychange'
    };
    for (stateKey in keys) {
      if (stateKey in document) {
        eventKey = keys[stateKey];
        break;
      }
    }
    merge(
      this.router.events.pipe(
        filter(event => event instanceof NavigationStart && !event.url.includes('login'))
      ),
      fromEvent(document, eventKey)
    ).subscribe(() => this.tokenExpirationHandler());
  }
}
