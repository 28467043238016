import { BsModalRef } from 'ngx-bootstrap';
import { IBPModalRef } from 'app/shared/models';

export function ModalRefD(modalRef: BsModalRef): IBPModalRef {
  const BPModalRef = modalRef as IBPModalRef;
  BPModalRef.hideBP = function () {
    modalRef.hide();
    return new Promise((resolve) => setTimeout(resolve, 200));
  };
  return BPModalRef;
}
